import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from './icon_button';
import DropdownMenuContainer from '../containers/dropdown_menu_container';
import { defineMessages, injectIntl } from 'react-intl';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { me, isStaff, domainName } from '../initial_state';
import classNames from 'classnames';
import { formatDistanceToNow } from 'date-fns';
import { plainDomain } from '../features/eunomia';
import { isMobile } from '../is_mobile';
import trust from '../../images/trust.png';
import mistrust from '../../images/mistrust.png';
import trusted from '../../images/trusted.png';
import mistrusted from '../../images/mistrusted.png';
import info from '../../images/info.png';
import infoCascade from '../../images/infoCascade.gif';

const messages = defineMessages({
  delete: { id: 'status.delete', defaultMessage: 'Delete' },
  redraft: { id: 'status.redraft', defaultMessage: 'Delete & re-draft' },
  direct: { id: 'status.direct', defaultMessage: 'Direct message @{name}' },
  mention: { id: 'status.mention', defaultMessage: 'Mention @{name}' },
  mute: { id: 'account.mute', defaultMessage: 'Mute @{name}' },
  block: { id: 'account.block', defaultMessage: 'Block @{name}' },
  reply: { id: 'status.reply', defaultMessage: 'Reply' },
  share: { id: 'status.share', defaultMessage: 'Share' },
  more: { id: 'status.more', defaultMessage: 'More' },
  replyAll: { id: 'status.replyAll', defaultMessage: 'Reply to thread' },
  reblog: { id: 'status.reblog', defaultMessage: 'Boost' },
  reblog_private: { id: 'status.reblog_private', defaultMessage: 'Boost with original visibility' },
  cancel_reblog_private: { id: 'status.cancel_reblog_private', defaultMessage: 'Unboost' },
  cannot_reblog: { id: 'status.cannot_reblog', defaultMessage: 'This post cannot be boosted' },
  favourite: { id: 'status.favourite', defaultMessage: 'Favourite' },
  bookmark: { id: 'status.bookmark', defaultMessage: 'Bookmark' },
  removeBookmark: { id: 'status.remove_bookmark', defaultMessage: 'Remove bookmark' },
  open: { id: 'status.open', defaultMessage: 'Expand this status' },
  report: { id: 'status.report', defaultMessage: 'Report @{name}' },
  muteConversation: { id: 'status.mute_conversation', defaultMessage: 'Mute conversation' },
  unmuteConversation: { id: 'status.unmute_conversation', defaultMessage: 'Unmute conversation' },
  pin: { id: 'status.pin', defaultMessage: 'Pin on profile' },
  unpin: { id: 'status.unpin', defaultMessage: 'Unpin from profile' },
  embed: { id: 'status.embed', defaultMessage: 'Embed' },
  admin_account: { id: 'status.admin_account', defaultMessage: 'Open moderation interface for @{name}' },
  admin_status: { id: 'status.admin_status', defaultMessage: 'Open this status in the moderation interface' },
  copy: { id: 'status.copy', defaultMessage: 'Copy link to status' },
  blockDomain: { id: 'account.block_domain', defaultMessage: 'Block domain {domain}' },
  unblockDomain: { id: 'account.unblock_domain', defaultMessage: 'Unblock domain {domain}' },
  unmute: { id: 'account.unmute', defaultMessage: 'Unmute @{name}' },
  unblock: { id: 'account.unblock', defaultMessage: 'Unblock @{name}' },
});

const mapStateToProps = (state, { status }) => ({
  relationship: state.getIn(['relationships', status.getIn(['account', 'id'])]),
});

export default @connect(mapStateToProps)
@injectIntl
class StatusActionBar extends ImmutablePureComponent {

  static contextTypes = {
    router: PropTypes.object,
  };

  state = {
    infoOpen: false,
    voting: false,
    voted: false,
    myVote: null,
  };

  static propTypes = {
    status: ImmutablePropTypes.map.isRequired,
    eunomia: ImmutablePropTypes.map,
    cascade: ImmutablePropTypes.map,
    stats: ImmutablePropTypes.map,
    onVoteRequest: PropTypes.func,
    onCascadeToggle: PropTypes.func,
    relationship: ImmutablePropTypes.map,
    onReply: PropTypes.func,
    onFavourite: PropTypes.func,
    onReblog: PropTypes.func,
    onDelete: PropTypes.func,
    onDirect: PropTypes.func,
    onMention: PropTypes.func,
    onMute: PropTypes.func,
    onUnmute: PropTypes.func,
    onBlock: PropTypes.func,
    onUnblock: PropTypes.func,
    onBlockDomain: PropTypes.func,
    onUnblockDomain: PropTypes.func,
    onReport: PropTypes.func,
    onEmbed: PropTypes.func,
    onMuteConversation: PropTypes.func,
    onPin: PropTypes.func,
    onBookmark: PropTypes.func,
    withDismiss: PropTypes.bool,
    scrollKey: PropTypes.string,
    intl: PropTypes.object.isRequired,
  };

  // Avoid checking props that are functions (and whose equality will always
  // evaluate to false. See react-immutable-pure-component for usage.
  updateOnProps = [
    'status',
    'relationship',
    'withDismiss',
    'eunomia',
    'cascade',
    'stats',
  ]
  mounted = false;

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.voting && this.mounted && this.state.voting) {
      this.setState({ voting: false, voted: true });
    } else if (!prevState.voting && this.mounted && this.state.voted) {
      this.setState({ voting: false, voted: false });
    }
  }

  _properStatus () {
    const { status } = this.props;

    if (status.get('reblog', null) !== null && typeof status.get('reblog') === 'object') {
      return status.get('reblog');
    } else {
      return status;
    }
  }


  handleReplyClick = () => {
    if (me) {
      this.props.onReply(this.props.status, this.context.router.history);
    } else {
      this._openInteractionDialog('reply');
    }
  }

  handleShareClick = () => {
    navigator.share({
      text: this.props.status.get('search_index'),
      url: this.props.status.get('url'),
    }).catch((e) => {
      if (e.name !== 'AbortError') console.error(e);
    });
  }

  handleFavouriteClick = () => {
    if (me) {
      this.props.onFavourite(this.props.status);
    } else {
      this._openInteractionDialog('favourite');
    }
  }

  handleReblogClick = e => {
    if (me) {
      this.props.onReblog(this.props.status, e);
    } else {
      this._openInteractionDialog('reblog');
    }
  }

  _openInteractionDialog = type => {
    window.open(`https://${domainName}/interact/${this.props.status.get('id')}?type=${type}`, 'mastodon-intent', 'width=445,height=600,resizable=no,menubar=no,status=no,scrollbars=yes');
  }

  handleBookmarkClick = () => {
    this.props.onBookmark(this.props.status);
  }

  handleDeleteClick = () => {
    this.props.onDelete(this.props.status, this.context.router.history);
  }

  handleRedraftClick = () => {
    this.props.onDelete(this.props.status, this.context.router.history, true);
  }

  handlePinClick = () => {
    this.props.onPin(this.props.status);
  }

  handleMentionClick = () => {
    this.props.onMention(this.props.status.get('account'), this.context.router.history);
  }

  handleDirectClick = () => {
    this.props.onDirect(this.props.status.get('account'), this.context.router.history);
  }

  handleMuteClick = () => {
    const { status, relationship, onMute, onUnmute } = this.props;
    const account = status.get('account');

    if (relationship && relationship.get('muting')) {
      onUnmute(account);
    } else {
      onMute(account);
    }
  }

  handleBlockClick = () => {
    const { status, relationship, onBlock, onUnblock } = this.props;
    const account = status.get('account');

    if (relationship && relationship.get('blocking')) {
      onUnblock(account);
    } else {
      onBlock(status);
    }
  }

  handleBlockDomain = () => {
    const { status, onBlockDomain } = this.props;
    const account = status.get('account');

    onBlockDomain(account.get('acct').split('@')[1]);
  }

  handleUnblockDomain = () => {
    const { status, onUnblockDomain } = this.props;
    const account = status.get('account');

    onUnblockDomain(account.get('acct').split('@')[1]);
  }

  handleOpen = () => {
    this.context.router.history.push(`/statuses/${this.props.status.get('id')}`);
  }

  handleEmbed = () => {
    this.props.onEmbed(this.props.status);
  }

  handleReport = () => {
    this.props.onReport(this.props.status);
  }

  handleConversationMuteClick = () => {
    this.props.onMuteConversation(this.props.status);
  }

  handleCopy = () => {
    const url      = this.props.status.get('url');
    const textarea = document.createElement('textarea');

    textarea.textContent    = url;
    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);

    try {
      textarea.select();
      document.execCommand('copy');
    } catch (e) {

    } finally {
      document.body.removeChild(textarea);
    }
  }

  updateTrust = (trust) => {
    let isToggle = false;
    let isDelete = false;
    if (this.props.eunomia && !this.state.voting) {
      const voted = this.props.eunomia.get('voted', false);
      if (voted === true) {
        const trusted = this.props.eunomia.get('trusted', false);
        if (trusted === trust) {
          // already voted (trust/mistrust) and triggered the same action (trust/mistrust)
          isDelete = true;
        } else if (trusted === !trust) {
          // already voted and triggered the other action (trust => mistrust | mistrust => trust)
          isToggle = true;
        }
      }
      this.props.onVoteRequest(this.props.eunomia, this.props.status, trust, isDelete, isToggle);
      this.setState({ voting: true, voted: false, myVote: isDelete ? null : isToggle ? !trust: trust });
    }
  };


  handleTrust = (e) => {
    e.preventDefault();
    this.updateTrust(true);
  };

  handleMistrust = (e) => {
    e.preventDefault();
    this.updateTrust(false);
  };

  handleInfoView = () => {
    this.setState({ infoOpen: !this.state.infoOpen });
  };

  handleCascadeView = (e) => {
    e.preventDefault();
    this.props.onCascadeToggle();
  };

  render () {
    const { status, relationship, intl, withDismiss, scrollKey, eunomia, cascade } = this.props;

    const anonymousAccess    = !me;
    const publicStatus       = ['public', 'unlisted'].includes(status.get('visibility'));
    const mutingConversation = status.get('muted');
    const account            = status.get('account');
    const writtenByMe        = status.getIn(['account', 'id']) === me;

    let menu = [];

    menu.push({ text: intl.formatMessage(messages.open), action: this.handleOpen });

    if (publicStatus) {
      menu.push({ text: intl.formatMessage(messages.copy), action: this.handleCopy });
      menu.push({ text: intl.formatMessage(messages.embed), action: this.handleEmbed });
    }

    menu.push(null);

    menu.push({ text: intl.formatMessage(status.get('bookmarked') ? messages.removeBookmark : messages.bookmark), action: this.handleBookmarkClick });

    if (writtenByMe && publicStatus) {
      menu.push({ text: intl.formatMessage(status.get('pinned') ? messages.unpin : messages.pin), action: this.handlePinClick });
    }

    menu.push(null);

    if (writtenByMe || withDismiss) {
      menu.push({ text: intl.formatMessage(mutingConversation ? messages.unmuteConversation : messages.muteConversation), action: this.handleConversationMuteClick });
      menu.push(null);
    }

    if (writtenByMe) {
      menu.push({ text: intl.formatMessage(messages.delete), action: this.handleDeleteClick });
      menu.push({ text: intl.formatMessage(messages.redraft), action: this.handleRedraftClick });
    } else {
      menu.push({ text: intl.formatMessage(messages.mention, { name: account.get('username') }), action: this.handleMentionClick });
      menu.push({ text: intl.formatMessage(messages.direct, { name: account.get('username') }), action: this.handleDirectClick });
      menu.push(null);

      if (relationship && relationship.get('muting')) {
        menu.push({ text: intl.formatMessage(messages.unmute, { name: account.get('username') }), action: this.handleMuteClick });
      } else {
        menu.push({ text: intl.formatMessage(messages.mute, { name: account.get('username') }), action: this.handleMuteClick });
      }

      if (relationship && relationship.get('blocking')) {
        menu.push({ text: intl.formatMessage(messages.unblock, { name: account.get('username') }), action: this.handleBlockClick });
      } else {
        menu.push({ text: intl.formatMessage(messages.block, { name: account.get('username') }), action: this.handleBlockClick });
      }

      menu.push({ text: intl.formatMessage(messages.report, { name: account.get('username') }), action: this.handleReport });

      if (account.get('acct') !== account.get('username')) {
        const domain = account.get('acct').split('@')[1];

        menu.push(null);

        if (relationship && relationship.get('domain_blocking')) {
          menu.push({ text: intl.formatMessage(messages.unblockDomain, { domain }), action: this.handleUnblockDomain });
        } else {
          menu.push({ text: intl.formatMessage(messages.blockDomain, { domain }), action: this.handleBlockDomain });
        }
      }

      if (isStaff) {
        menu.push(null);
        menu.push({ text: intl.formatMessage(messages.admin_account, { name: account.get('username') }), href: `/admin/accounts/${status.getIn(['account', 'id'])}` });
        menu.push({ text: intl.formatMessage(messages.admin_status), href: `/admin/accounts/${status.getIn(['account', 'id'])}/statuses/${status.get('id')}` });
      }
    }

    let replyIcon;
    let replyTitle;
    if (status.get('in_reply_to_id', null) === null) {
      replyIcon = 'reply';
      replyTitle = intl.formatMessage(messages.reply);
    } else {
      replyIcon = 'reply-all';
      replyTitle = intl.formatMessage(messages.replyAll);
    }

    const reblogPrivate = status.getIn(['account', 'id']) === me && status.get('visibility') === 'private';

    let reblogTitle = '';
    if (status.get('reblogged')) {
      reblogTitle = intl.formatMessage(messages.cancel_reblog_private);
    } else if (publicStatus) {
      reblogTitle = intl.formatMessage(messages.reblog);
    } else if (reblogPrivate) {
      reblogTitle = intl.formatMessage(messages.reblog_private);
    } else {
      reblogTitle = intl.formatMessage(messages.cannot_reblog);
    }

    const shareButton = ('share' in navigator) && publicStatus && (
      <IconButton className='status__action-bar-button' title={intl.formatMessage(messages.share)} icon='share-alt' onClick={this.handleShareClick} />
    );
    const trustIcon = () => {
      let imgSrc = trust;
      let trustsStr = '';
      let voted = false;
      if (eunomia) {
        const trusts = eunomia.getIn(['votes', 'trusts'], null);
        try {
          const count = parseInt(trusts);
          trustsStr = !isNaN(count) ? `${count}` : '0';
        } catch {
        }
        voted = eunomia.get('voted', false);
        if (voted) {
          const _trusted = eunomia.get('trusted', false);
          if (_trusted) {
            imgSrc = trusted;
          }
        }
      }
      if (this.state.voting || this.state.voted) {
        imgSrc = this.state.myVote === true ? trusted : trust;
      }
      return (
        <div className='eunomia-menu-icon-wrapper'>
          <img src={imgSrc} alt='' className='eunomia-menu-icon' />
          <div
            className='eunomia-menu-icon-counter'
            id='eunomia-total-trusts-counter'
          >
            {trustsStr}
          </div>
        </div>
      );
    };

    const mistrustIcon = () => {
      let imgSrc = mistrust;
      let mistrustsStr = '';
      let voted = false;
      if (eunomia) {
        const mistrusts = eunomia.getIn(['votes', 'mistrusts'], null);
        try {
          const count = parseInt(mistrusts);
          mistrustsStr = !isNaN(count) ? `${count}` : '0';
        } catch {
        }
        voted = eunomia.get('voted', false);
        if (voted) {
          const _trusted = eunomia.get('trusted', true);
          if (!_trusted) {
            imgSrc = mistrusted;
          }
        }
      }
      if (this.state.voting || this.state.voted) {
        imgSrc = this.state.myVote === false ? mistrusted : mistrust;
      }
      return (
        <div className='eunomia-menu-icon-wrapper'>
          <img
            src={imgSrc}
            alt=''
            className='eunomia-menu-icon'
          />
          <div
            className='eunomia-menu-icon-counter'
            id='eunomia-total-mistrusts-counter'
          >
            {mistrustsStr}
          </div>
        </div>
      );
    };

    const indicators = () => {
      let imgSrc = info;
      if (eunomia && cascadePostsCount() > 0) {
        imgSrc = infoCascade;
      }
      return (
        <div className='eunomia-menu-icon-wrapper' style={{ marginRight: -6, marginLeft: 3, marginTop: -1, padding: 0 }}>
          <img src={imgSrc} alt='' className='eunomia-menu-icon' />
        </div>
      );
    };

    // only add eunomia additions on statuses created on our domain
    const sameDomain = () => plainDomain(this._properStatus().get('url')) === domainName;

    const canNotVote = (strict = false) => {
      if (!eunomia) {
        return true;
      }
      if (strict && this.state.voting === true) {
        return true;
      }
      return status.getIn(['account', 'id']) === me;
    };

    const canVote = (strict = false) => !canNotVote(strict);

    const cascadePostsCount = () => {
      if (!cascade) {
        return 0;
      }
      if (!eunomia) {
        return 0;
      }
      if (eunomia.get('processed', 'no') !== 'yes') {
        return 0;
      }
      return cascade.size - 1;
    };

    const extraEunomiaBar = () => isMobile(window.document.body.clientWidth);

    const eunomiaAddon = () => {
      return (
        <div className='eunomia-action-bar-wrapper' style={{ display: sameDomain() ? 'flex': 'none' }}>
          <IconButton
            className='status__action-bar-button eunomia-icon-button'
            style={{ marginRight: '0px!important' }}
            size={19}
            title={'EUNOMIA indicators'}
            // eslint-disable-next-line react/jsx-no-bind
            icon={indicators} onClick={this.handleInfoView}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              className='status__action-bar-button eunomia-icon-button'
              id='eunomia-trust-button'
              size={19}
              disabled={canNotVote(true)}
              title={'Trust this status'}
              // eslint-disable-next-line react/jsx-no-bind
              icon={trustIcon}
              onClick={this.handleTrust}
            />
            { canVote() &&
            <div
              role='button'
              className={canNotVote(true) ? 'voting-button-text-link-disabled': 'voting-button-text-link'}
              onClick={this.handleTrust}
              aria-disabled={canNotVote(true)}
              title='I trust this'
            >
              I trust this
            </div>
            }
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              className='status__action-bar-button eunomia-icon-button'
              id='eunomia-mistrust-button'
              size={20}
              disabled={canNotVote(true)}
              title={'Mistrust this status'}
              // eslint-disable-next-line react/jsx-no-bind
              icon={mistrustIcon}
              onClick={this.handleMistrust}
            />
            { canVote() &&
            <div
              role='button'
              className={canNotVote(true) ? 'voting-button-text-link-disabled': 'voting-button-text-link'}
              onClick={this.handleMistrust}
              aria-disabled={canNotVote(true)}
              title="I don't trust this"
            >
              I don't trust this
            </div>
            }
          </div>
        </div>
      );
    };

    const showNudge = () => {
      return sameDomain() && false;
    };

    return (
      <div>
        <div className='eunomia-nudge' style={{ display: showNudge() ? 'inherit': 'none' }}>
          Would you like to try rating this post’s trustworthiness by pressing one of the buttons below?
        </div>
        <div className='status__action-bar'>
          { sameDomain() ? !extraEunomiaBar() ? eunomiaAddon() : null : null}
          <IconButton className='status__action-bar-button' title={replyTitle} icon={status.get('in_reply_to_account_id') === status.getIn(['account', 'id']) ? 'reply' : replyIcon} onClick={this.handleReplyClick} counter={status.get('replies_count')} obfuscateCount />
          <IconButton className={classNames('status__action-bar-button', { reblogPrivate })} disabled={!publicStatus && !reblogPrivate}  active={status.get('reblogged')} pressed={status.get('reblogged')} title={reblogTitle} icon='retweet' onClick={this.handleReblogClick} />
          <IconButton className='status__action-bar-button star-icon' animate active={status.get('favourited')} pressed={status.get('favourited')} title={intl.formatMessage(messages.favourite)} icon='star' onClick={this.handleFavouriteClick} />

          {shareButton}

          <div className='status__action-bar-dropdown'>
            <DropdownMenuContainer
              scrollKey={scrollKey}
              disabled={anonymousAccess}
              status={status}
              items={menu}
              icon='ellipsis-h'
              size={18}
              direction='right'
              title={intl.formatMessage(messages.more)}
            />
          </div>
        </div>{ sameDomain() ? extraEunomiaBar() ? (
          <div className='status__action-bar'>
            { eunomiaAddon() }
          </div>
        ) : null: null }
        { sameDomain() ? (
          <div className='eunomia-info-view' style={{ display: this.state.infoOpen ? 'flex' : 'none' }}>
            <div>
              <div style={{ float: 'right' }} role='button' onClick={this.handleInfoView} className='eunomia-indicators-close' />
            </div>
            <div>
              Account Age: <span style={{ fontWeight: 'bolder' }}>{formatDistanceToNow(new Date(status.getIn(['account', 'created_at']))).replace('about ', '')}</span>
            </div>
            <div>
              Connections: <span style={{ fontWeight: 'bolder' }}>{status.getIn(['account', 'followers_count'])} followers / {status.getIn(['account', 'following_count'])} following</span>
            </div>
            <div>
              Posts: <span style={{ fontWeight: 'bolder' }}>{status.getIn(['account', 'statuses_count'])}</span>
            </div>
            {  eunomia && eunomia.get('cascade_id', null) !== null && cascadePostsCount() > 0  ? (
              <div style={{ marginTop: 10 }}>
                <a
                  href={`${window.location.href}/#`}
                  className='eunomia-cascade-link'
                  title='Similar posts'
                  onClick={this.handleCascadeView}
                >
                  Show other similar posts ({cascadePostsCount()})
                </a>
              </div>
            ) : null }
          </div>
        ) : null }
      </div>
    );
  }

}
