const element = document.getElementById('initial-state');
const initialState = element && JSON.parse(element.textContent);

const getMeta = (prop) => initialState && initialState.meta && initialState.meta[prop];
const getAccountProp = (accountId, prop) => initialState && initialState.accounts && initialState.accounts[accountId] && initialState.accounts[accountId][prop];

export const reduceMotion = getMeta('reduce_motion');
export const autoPlayGif = getMeta('auto_play_gif');
export const displayMedia = getMeta('display_media');
export const expandSpoilers = getMeta('expand_spoilers');
export const unfollowModal = getMeta('unfollow_modal');
export const boostModal = getMeta('boost_modal');
export const deleteModal = getMeta('delete_modal');
export const me = getMeta('me');
export const searchEnabled = getMeta('search_enabled');
export const invitesEnabled = getMeta('invites_enabled');
export const repository = 'eunomia-social/dc';
export const source_url = 'https://gitlab.com/eunomia-social/dc';
export const version = '0.5.1';
export const mascot = getMeta('mascot');
export const profile_directory = getMeta('profile_directory');
export const isStaff = getMeta('is_staff');
export const forceSingleColumn = !getMeta('advanced_layout');
export const useBlurhash = getMeta('use_blurhash');
export const usePendingItems = getMeta('use_pending_items');
export const showTrends = getMeta('trends');
export const title = getMeta('title');
export const cropImages = getMeta('crop_images');
export const disableSwiping = getMeta('disable_swiping');
export const accessToken = getMeta('access_token');
export const domainName = getMeta('domain');
export const myUrl = getAccountProp(me, 'url');
export const myId = `${me}@mastodon`;
export const eunomiaDomain = getMeta('domain');
export const streaming_api_base_url = getMeta('streaming_api_base_url');

export default initialState;
