import axios from 'axios';
import voting from './voting/lib';
import { getMotd } from './motd';
import {
  EUNOMIA_INIT,
  ACCOUNTS_DB_KEY,
  TOKEN_DURATION,
  EUNOMIA_STATS_IMPORT,
  EUNOMIA_VERSION,
  EUNOMIA_HEADER_TOKEN,
  HEADER_SN_TOKEN,
  HEADER_SN_URL,
  HEADER_ACCOUNT_URL,
  SINGLE_PANEL_THRESHOLD,
} from './constants';
import Storage from './storage';
import { accessToken, domainName, eunomiaDomain, myId, myUrl } from '../../initial_state';


export { getMotd };

const { db } = voting;
export { db };

export const inSinglePanel = () => window.innerWidth <= SINGLE_PANEL_THRESHOLD;

export const plainDomain = (url) => {
  if (url) {
    const regex = /(https|http):\/\//g;
    return url.replace(regex, '').split('/')[0];
  }
  return url;
};

export const htmlToText = (html) => {
  let temp = document.createElement('div');
  temp.innerHTML = html;
  return temp.textContent || temp.innerText || '';
};

export const wordsCountOld = (str) => {
  return htmlToText(str).split(' ')
    .filter(function(n) {
      return n !== '';
    })
    .length;
};

export const wordsCount = (str) => {
  // source/credits:
  // https://stackoverflow.com/questions/20396456/how-to-do-word-counts-for-a-mixture-of-english-and-chinese-in-javascript
  let text = htmlToText(str);
  text= text.replace(/[\u007F-\u00FE]/g,' ');
  let str1=text;
  let str2=text;
  str1=str1.replace(/[^!-~\d\s]+/gi,' ');
  str2=str2.replace(/[!-~\d\s]+/gi,'');
  const matches1 = str1.match(/[\u00ff-\uffff]|\S+/g);
  const matches2 = str2.match(/[\u00ff-\uffff]|\S+/g);
  const count1= matches1 ? matches1.length : 0;
  const count2= matches2 ? matches2.length : 0; 
  return count1+count2;
}

export const isValidURL = (str) => {
  const res = str.match(
    /(^(http(s)?:\/\/)(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-z]{1,6}\b([-a-zA-Z0-9@:%_.~#?&/=]*))|(^(http(s)?:\/\/)(localhost|(\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b))(:[0-9]*)?)/g,
  );
  return res !== null;
};


const saveEunomiaToken = (domain, dbEntry, cb) => {
  const accounts= { mastodon: [], active: null, eunomia: {} };
  Storage.get(ACCOUNTS_DB_KEY)
    .then((dbAccounts) => {
      if (dbAccounts && dbAccounts.mastodon) {
        accounts.mastodon = dbAccounts.mastodon;
      }
      if (dbAccounts && dbAccounts.eunomia) {
        accounts.eunomia = dbAccounts.eunomia;
      }
    })
    .catch(() => {})
    .finally(() => {
      accounts.eunomia[domain] = dbEntry;
      Storage.set(ACCOUNTS_DB_KEY, accounts).then(()=>{
        cb(true);
      }).catch(()=>{
        cb(false);
      });
    });
};

const _fetchToken  = (eunomiaUri, snUri, snToken, resolve, reject, force = false) => {
  if (!window.sessionStorage.getItem('GT') || (force !== undefined && force === true)) {
    window.sessionStorage.setItem('GT', '1');
    const snDomain = plainDomain(snUri);
    axios
      .get(`${eunomiaUri}/eunomia/token?auth_provider_uri=${snDomain}&access_token=${snToken}`)
      .then(({ data }) => {
        const responseData = data.data;
        if (responseData && responseData.token) {
          const created = new Date();
          const dbEntry = { token: responseData.token, created, srcToken: snToken };
          const eunomiaDomain = plainDomain(eunomiaUri);
          saveEunomiaToken(eunomiaDomain, dbEntry, (success) => {
            if (!success) {
              console.warn('could not save entry');
            }
            if (window.sessionStorage.getItem('GT')) {
              window.sessionStorage.removeItem('GT');
            }
            resolve(dbEntry);
          });
        } else {
          if (window.sessionStorage.getItem('GT')) {
            window.sessionStorage.removeItem('GT');
          }
          console.warn('ERROR: _fetchToken(): ', data);
          reject({ message: 'Could not get a token :(' });
        }
      }).catch((reason) => {
        if (window.sessionStorage.getItem('GT')) {
          window.sessionStorage.removeItem('GT');
        }
        console.warn('_fetchToken(): ', reason);
        reject(reason);
      });
  } else {
    reject({ 'message': 'already fetching' });
  }
};

const _checkToken = (eunomiaUri) => new Promise(((resolve) => {
  try {
    const _eunomiaUri = plainDomain(eunomiaUri);
    db
      .get(ACCOUNTS_DB_KEY)
      .then((response) => {
        if (response && response.eunomia && response.eunomia[_eunomiaUri]) {
          const { token, created } = response.eunomia[_eunomiaUri];
          if (!token || !created) {
            resolve(null);
          } else {
            const _created = new Date(created);
            _created.setTime(_created.getTime() + TOKEN_DURATION - 60000);
            const now = new Date();
            if (_created < now) {
              resolve(null);
            } else {
              resolve({ token, id: myId, created });
            }
          }
        } else {
          resolve(null);
        }
      }).catch(() => {
        resolve(null);
      });
  } catch (_) {
    resolve(null);
  }
}));

export const getToken = (eunomiaUrl, snUrl, snToken, force = false) => new Promise(((resolve, reject) => {
  _checkToken(eunomiaUrl).then((existing) => {
    if (existing && (force === undefined || force === false)) {
      resolve(existing);
    } else {
      _fetchToken(eunomiaUrl, snUrl, snToken, resolve, reject, force);
    }
  }).catch(() => {
    _fetchToken(eunomiaUrl, snUrl, snToken, resolve, reject, force);
  });
}));

export const clearDb = ()  => new Promise(((resolve, reject) => {
  db.clear().then((result => resolve(result))).catch((reason) => reject(reason));
}));

export const initVoting = (nodeEndpoint, votingEndpoint, eunomiaToken, eunomiaUserId) => new Promise(((resolve, reject) => {
  const domain = plainDomain(nodeEndpoint);
  const snUrl = `https://${domain}`;
  const secret = 'n0t@$3cr3t';
  voting.initialize(nodeEndpoint, votingEndpoint, eunomiaToken, eunomiaUserId, false, secret).then((loginResult) => {
    resolve(loginResult);
  }).catch(() => {
    voting.initialize(nodeEndpoint, votingEndpoint, eunomiaToken, eunomiaUserId, true, secret).then((registerResult) => {
      resolve(registerResult);
    }).catch((reason) => {
      if (reason && reason.code && reason.code === 401) {
        // invalid token ?  let's try one more time with a new one
        _fetchToken(nodeEndpoint, snUrl, accessToken, (data) => {
          if (data && data.token) {
            voting.initialize(nodeEndpoint, votingEndpoint, data.token, eunomiaUserId, false, secret).then((loginResult) => {
              resolve(loginResult);
            }).catch(() => {
              voting.initialize(nodeEndpoint, votingEndpoint, data.token, eunomiaUserId, true, secret).then((registerResult) => {
                resolve(registerResult);
              }).catch((reason3) => {
                reject(reason3);
              });
            });
          } else {
            reject(reason);
          }
        }, (reason2) => {
          reject(reason2);
        }, true);
      } else {
        reject(reason);
      }
    });
  });
}));

const checkVersion =(cb) => {
  const url =`https://${eunomiaDomain}/eunomia/api/version`;
  axios.get(url).then(({ data }) => {
    if (data && data.version) {
      const currentVersion = data.version;
      db.get(ACCOUNTS_DB_KEY).then((entry) => {
        if (!entry || (entry && entry.version && entry.version !== currentVersion)) {
          db.clear().then(() => {
            db.save(EUNOMIA_VERSION, data).then(() => cb()).catch(() => cb());
          }).catch(() => {
            db.save(EUNOMIA_VERSION, data).then(() => cb()).catch(() => cb());
          });
        } else {
          db.save(EUNOMIA_VERSION, data).then(() => cb()).catch(() => cb());
        }
      }).catch(() => {
        db.save(EUNOMIA_VERSION, data).then(() => cb()).catch(() => cb());
      });
    } else {
      cb();
    }
  }).catch(() => cb());
};


export const getEunomiaToken = (force=false) => new Promise((resolve, reject) => {
  const domain = plainDomain(eunomiaDomain);
  const snUri = `https://${domain}`;
  const eunomiaUri = `https://${domain}/storage`;
  getToken(eunomiaUri, snUri, accessToken, force).then((data) => {
    resolve(data);
  }).catch((reason) => {
    reject(reason);
  });
});


export const getEunomiaHeaders = () => {
  return new Promise(((resolve, reject) => {
    const headers = {};
    getEunomiaToken().then((data) => {
      if (data) {
        const { token } = data;
        headers[EUNOMIA_HEADER_TOKEN] = token;
        headers[HEADER_SN_TOKEN] = accessToken;
        headers[HEADER_SN_URL] = `https://${domainName}`;
        headers[HEADER_ACCOUNT_URL] = myUrl;
        resolve(headers);
      }
    }).catch((reason) => {
      reject(reason);
    });
  }));
};

const _onGotToken = (data, dispatch) => {
  const { token } = data;
  const domain = plainDomain(eunomiaDomain);
  const nodeEndpoint = `https://${domain}/storage`;
  const votingEndpoint = `https://${domain}/voting`;
  // eslint-disable-next-line no-console,promise/catch-or-return
  initVoting(nodeEndpoint, votingEndpoint, token, myId).then(() => {}).catch(() => {
    initVoting(nodeEndpoint, votingEndpoint, token, myId).then(() => {}).catch(()=>{
      setTimeout(() => {
        initVoting(nodeEndpoint, votingEndpoint, token, myId).then(() => {
        }).catch(() => {
          voting.clear().then(() => {
            initVoting(nodeEndpoint, votingEndpoint, token, myId).then(() => {}).catch((reason) => {
              console.warn(reason);
            });
          }).catch(()=>{});
        });
      }, 3000);
    }).catch(()=>{});
  }).finally(() => {
    dispatch({ type: EUNOMIA_INIT });
    const snUri = `https://${domain}`;
    getEunomiaHeaders().then((headers) => {
      axios.get(`${snUri}/eunomia/api/me`, { headers }).then((response) => {
        if (response.data && response.data.stats) {
          dispatch({ type: EUNOMIA_STATS_IMPORT, payload: response.data.stats });
        }
      }).catch(console.warn);
    }).catch();
  });
};

export const eunomiaInit = () => (dispatch) => {
  if (window.sessionStorage.getItem('GT')) {
    window.sessionStorage.removeItem('GT');
  }
  checkVersion(() => {
    getEunomiaToken().then((data) => {
      if (data) {
        _onGotToken(data, dispatch);
      }
    }).catch((e) => {
      console.warn(e);
      setTimeout(() => {
        getEunomiaToken().then((data) => {
          if (data) {
            _onGotToken(data, dispatch);
          }
        }).catch(()=>{
          // try one last time (voting/storage service not ready/initialized yet?)
          setTimeout(() => {
            getEunomiaToken().then((data) => {
              if (data) {
                _onGotToken(data, dispatch);
              }
            }).catch((reason)=>console.warn(reason));
          }, 3000);
        });
      }, 2000);
    });
  });
};

export const handleItem = (item) => {
  let result = { ...item };
  function traverse(jsonObj) {
    if (Object.prototype.toString.call(jsonObj) === '[object String]') {
      if (jsonObj.startsWith('http:') && jsonObj.includes(domainName) && window.location.protocol.startsWith('https')) {
        jsonObj = jsonObj.replace('http', 'https');
      }
    } else {
      if (jsonObj !== null && typeof jsonObj === 'object') {
        Object.entries(jsonObj).forEach(([key, value]) => {
          // key is either an array index or object key
          jsonObj[key] = traverse(value);
        });
      }
    }
    return jsonObj;
  }
  return traverse(result);
};

export const sortPosts = (posts) => {
  return posts
    .sort(
      (a, b) => new Date(b.get('created_at')).getTime() - new Date(a.get('created_at')).getTime());
};

export const setBodyOverflowY = (style = 'clip') => {
  if (inSinglePanel() && window.document.body.style.overflowY !== style) {
    window.document.body.style.overflowY = style;
  }
};
