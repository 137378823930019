export const EUNOMIA_VERSION = 'EUNOMIA_VERSION';
export const EUNOMIA_VOTING_INIT = 'EUNOMIA_VOTING_INIT';
export const EUNOMIA_HEADER_TOKEN = 'X-EUNOMIA-TOKEN';
export const HEADER_SN_TOKEN = 'X-SN-TOKEN';
export const HEADER_SN_URL = 'X-SN-URL';
export const HEADER_ACCOUNT_URL = 'X-ACCOUNT-URL';
export const EUNOMIA_STATS_IMPORT = 'EUNOMIA_STATS_IMPORT';
export const CURRENT_USER_VOTED = 'CURRENT_USER_VOTED';
export const EUNOMIA_TOKEN = 'EUNOMIA_TOKEN';
export const TOKEN_DURATION = 3600000;
export const EUNOMIA_INIT = 'EUNOMIA_INIT';
export const ACCOUNTS_DB_KEY = 'DC_ACCOUNTS';
export const APP_PREFERENCES = 'APP_PREFERENCES';
export const APPS_DB_KEY = 'MASTODON_APPS';
export const EUNOMIA_APP_TERMS = 'EUNOMIA_APP_TERMS';
export const EUNOMIA_CASCADE_OPEN = 'EUNOMIA_CASCADE_OPEN';
export const DELETE_POST = 'DELETE_POST';
export const EUNOMIA_PENDING = 'EUNOMIA_PENDING';
export const EUNOMIA_POST_IMPORT = 'EUNOMIA_POST_IMPORT';
export const EUNOMIA_POSTS_IMPORT = 'EUNOMIA_POSTS_IMPORT';
export const EUNOMIA_CASCADE_IMPORT = 'EUNOMIA_CASCADE_IMPORT';
export const EUNOMIA_CASCADE_POST_UPDATE = 'EUNOMIA_CASCADE_POST_UPDATE';
export const SINGLE_PANEL_THRESHOLD = 895;
